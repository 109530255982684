
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import {
  IOfferOption,
  OfferOptionType,
  OfferOptionTypes,
} from '@alao-frontend/core'
import OfferOptionsItemDetail from '~/components/offers/OfferOptions/OfferOptionsItemDetail.vue'
import OfferOptionsItemBenefit from '~/components/offers/OfferOptions/OfferOptionsItemBenefit.vue'
import OfferBenefitBadge from '~/components/offers/benefits/OfferBenefitBadge.vue'
import IconData from '~/assets/icons/offer-details/icon-data.svg?inline'
import IconDataRoaming from '~/assets/icons/offer-details/icon-data-roaming.svg?inline'
import IconCalls from '~/assets/icons/offer-details/icon-calls.svg?inline'
import IconCallsOutside from '~/assets/icons/offer-details/icon-calls-outside.svg?inline'
import IconNetwork from '~/assets/icons/offer-details/icon-network.svg?inline'
import IconContractDuration from '~/assets/icons/offer-details/icon-contract-length.svg?inline'

@Component({
  components: {
    OfferOptionsItemDetail,
    OfferOptionsItemBenefit,
    OfferBenefitBadge,
    IconData,
    IconDataRoaming,
    IconCalls,
    IconCallsOutside,
    IconNetwork,
    IconContractDuration,
  },
})
export default class OfferOptions extends Vue {
  @Prop({
    required: false,
    default: OfferOptionType.DETAILS,
    type: String,
    validator (value: OfferOptionType): boolean {
      return OfferOptionTypes.includes(value)
    },
  })
  readonly optionsType!: OfferOptionType

  @Prop({
    required: true,
    default: () => [],
    type: Array,
  })
  readonly options!: IOfferOption[]

  @Prop({
    required: false,
    default: 'transparent',
    type: String,
  })
  readonly bgColor!: boolean

  @Prop({
    required: false,
    default: '#444444',
    type: String,
  })
  readonly textColor!: boolean

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly withIcons!: boolean

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly highlightBold!: boolean

  get component () {
    switch (this.optionsType) {
      case OfferOptionType.DETAILS:
        return OfferOptionsItemDetail

      case OfferOptionType.BENEFITS:
        return OfferOptionsItemBenefit
    }
  }

  getAttributes (option: IOfferOption) {
    if (option.attributes) {
      return {
        'data-benefit-key': option.attributes?.key,
        'data-value': option.attributes?.value,
      }
    }
  }
}
