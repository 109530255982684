
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import OfferOptionsItemBase from '~/components/offers/OfferOptions/OfferOptionsItemBase.vue'
import IconCheck from '~/assets/icons/icon-check.svg?inline'

@Component({
  components: {
    OfferOptionsItemBase,
    IconCheck,
  },
})
export default class OfferOptionsItemDetail extends Vue {
  @Prop({
    required: false,
    default: 'transparent',
    type: String,
  })
  readonly bgColor!: string

  @Prop({
    required: false,
    default: '#444444',
    type: String,
  })
  readonly textColor!: string

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly highlightBold!: boolean
}
